/*! _navigation.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Website sidebar
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Website sidebar
=============================================================================
***/

/* ==========================================================================
0. Website sidebar
========================================================================== */
.side-navigation-menu {
    display: flex;
    justify-content: flex-start;
    background: $sidebar;
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    //width:300px;
    overflow:hidden;
    transform: translateX(-301px);
    -webkit-transition:width .05s linear;
    transition: width .05s linear;
    transition: transform 0.3s;
    z-index:1000;
    //Active state
    &.is-active {
        transform: translateX(0);
    }
    //Categories side menu
    .category-menu-wrapper {
        height: 100%;
        position: relative;
        width: 80px;
        background: $secondary;
        ul.categories {
            li {
                height: 60px;
                &.square-logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-height: 28px;
                    }
                }
                &.category-link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    i {
                        font-size: 20px;
                        color: $white;
                    }
                    &:hover {
                        background: darken($secondary, 5%);
                    }
                }
                &.is-active  {
                    background: darken($secondary, 10%);
                }
            }
        }

    }

}