.hc-raised {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.hc-content {
    border-radius: 5px;
    margin: -60px 25px 60px;
    position: relative;
    background-color: #fff;
    z-index: 2;
}

@media only screen and (max-width: 768px) {
    .hc-content {
        margin: -60px 0 60px;
        border-radius: 0;
    }
}

.mar-top {
    margin-top: -100px;
}

.for-modal {
    cursor: pointer;
    &:hover {
        border-radius: 10px;
        box-shadow: 0 20px 60px 0 rgba(0, 131, 255, 0.2);
    }
}

.modal-pic {
    padding-bottom: 30px;
    border-bottom: dashed #a3a3a3 1px;
    // figure {
    //     img {
    //         border-radius: 10px;
    //         box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    //     }
    // }
}
